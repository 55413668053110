import React, { PureComponent } from 'react';
import { Button, Space } from 'antd';
import { Layout } from '@/components';
import ProductForm from '../components/ProductForm';
import { hideLoading, showLoading } from '@/components/message/message';
import WebApi from '@/web/api';
import LibEnum from 'lib/enum';

export default class ProductCreatePage extends PureComponent<RouterPropsType> {
  private productForm = React.createRef<ProductForm>();

  state = {
    loading: false,
  }

  render() {
    return (
      <Layout.Page>
        <Layout flex1 scrollY>
          <ProductForm ref={this.productForm} status={LibEnum.PRODUCT_STATUS.UP.value}/>
        </Layout>
        <Layout.FootBar>
          <Space>
            <Button type="ghost">取消</Button>
            <Button type="primary" ghost onClick={this.clickSaveAndCreate}>保存并新增</Button>
            <Button type="primary" disabled={this.state.loading} loading={this.state.loading} onClick={this.clickSave}>保存</Button>
          </Space>
        </Layout.FootBar>
       
      </Layout.Page>
    )
  }

  save = async(callback) => {
    this.productForm.current?.validateFields()
    ?.then((values) => {
      this.setState({
        loading: true,
      });
      showLoading();
      return WebApi.product_create(values)
    })
    .then(() => {
      showSuccess.save();
      callback();
    })
    .catch(e => {
      console.error(e);
    })
    .finally(() => {
      this.setState({
        loading: false,
      });
      hideLoading();
    });
  }

  clickSaveAndCreate = () => {
    this.save(() => {
      this.productForm.current?.reset();
    });
  }

  clickSave = () => {
    this.save(() => {
      this.props.history.close();
    })
  }
}